<template lang="pug">
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import convertCurrencyToSymbol from '@/utils/convertCurrencyToSymbol';
import {displayCoins} from '@/utils/moneyUtils';

export default {
  name: 'LeftMenuMobile',
  data: () => ({
    isExpanded: false,
    moneyCurency: convertCurrencyToSymbol,
    moneyFormat: displayCoins,
    sunduk_w: {
      items: [
        {id: 'root', title: 'home', route: 'root'},
        {id: 'games', title: 'games', route: 'games'},
        {id: 'nards', title: 'nards', route: 'nards'},
        {id: 'promotions', title: 'promotions', route: 'root'},
      ],
      itemsAuth: [
        {id: 'root', title: 'home', route: 'root'},
        {id: 'games', title: 'games', route: 'games'},
        {id: 'nards', title: 'nards', route: 'nards'},
        {id: 'promotions', title: 'promotions', route: 'root'},
      ]
    },
    ex_w: {
      items: [
        {id: 'root', title: 'exchange', route: 'root'},
        {id: 'fee', title: 'fee', route: 'root'}
      ],
      itemsAuth: [
        {id: 'root', title: 'exchange', route: 'root'},
        {id: 'funds', title: 'funds', route: 'funds'},
        {id: 'history', title: 'history', route: 'history'},
        {id: 'fee', title: 'fee', route: 'root'},
      ]
    },
  }),
  computed: {
    ...mapGetters({
      pages: 'main/getPages',
      openSection: 'personal/getOpenSection',
      userLanguageCode: 'user/getLanguageCode',
    }),
    isAuthUser() {
      return this.$store.getters['auth/isAuthUser'];
    },
    userBalance() {
      return this.isAuthUser? this.$store.getters['user/getBalance'] : null;
    },
    isBalance() {
      return typeof this.userBalance !== 'undefined' && this.userBalance !== null;
    },
    formatBalance() {
      return this.isBalance? this.moneyFormat(this.userBalance.value, this.userBalance, false, false) : '';
    },
    balance() {
        return this.isBalance ? `${this.formatBalance} ${this.moneyCurency(this.userBalance.currency)}` : '';
    },
    balanceBonus() {
      return '0 ' + this.moneyCurency(this.userBalance.currency);
    },
    cBrand() {
      return this.$store.getters['main/getBrand'];
    },
    leftMenuItems() {
      if (this.cBrand.code) {
        return this.isAuthUser ? this[this.cBrand.code].itemsAuth : this[this.cBrand.code].items;
      } else {
        return [];
      }
    },
    isEx() {
      return this.cBrand.code === 'ex_w';
    },
  },
  methods: {
    ...mapActions({
      doLogout: 'auth/doLogout',
      fetchGameUrl: 'games/fetchGameUrl',
      doPay: 'pay/doPay',
    }),
    ...mapMutations({
      SET_SIGNIN_VISIBLE: 'auth/SET_SIGNIN_VISIBLE',
      SET_OPEN_SECTION: 'personal/SET_OPEN_SECTION',
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
    }),
    menuToggle() {
      this.isExpanded = !this.isExpanded;
    },
    async logoutHandler() {
      this.menuToggle();
      this.doLogout();
      this.SET_OPEN_PAGE('root');
      this.$router.push('/');
      window.scrollTo(0, 0);
    },
    async loginHandler() {
      this.menuToggle();
      this.SET_SIGNIN_VISIBLE(true);
    },

    async openPage(url) {
      let page = this.pages[url] === '' ? 'root' : url;
      await this.$router.push(page === 'root' ? '/' : `/${page}`).catch((e) => {
        console.error(e);
      });
      window.scrollTo(0, 0);
      this.SET_OPEN_PAGE(page);
      this.menuToggle();
    },
    async openPersonalPage(url) {
      if (window.location.pathname !== '/personal') {
        await this.$router.push('/personal');
      }
      await this.SET_OPEN_SECTION(url);
      this.SET_OPEN_PAGE('personal');
      window.scrollTo(0, 0);
      this.menuToggle();
    },

    async openGame() {
      const gameId = 336;
      const url = await this.fetchGameUrl({gameId, homeButton: true, mobile: this.isMobile});
      if (url.length > 0) {
        window.location.href = url;
        if (this.$metrika) {
          this.$metrika.reachGoal('gameRun');
        }
      }
    },

    async clickMenu(item_menu) {
      let pagesList = ['root', 'games', 'history', 'funds', 'promotions'];
      let personalList = ['editProfile', 'security', 'settings', 'deposit', 'depositNew', 'withdrawal', 'paymentsHistory'];

      if (item_menu === 'depositNew') {
        this.doPay({type: 'depositNew'});
      } else {
        if (pagesList.includes(item_menu)) {
          await this.openPage(item_menu);
          return;
        }

        if (personalList.includes(item_menu)) {
          await this.openPersonalPage(item_menu);
          return;
        }

        if (item_menu === 'nards') {
          await this.openGame(item_menu);
          return;
        }
      }

      console.log(`Нет страницы: ${item_menu}`);
    },
  },

  mounted() {
    window.addEventListener(
        'message',
        (event) => {
          if (
              event.origin === window.location.origin &&
              event.data === 'LeftMenuMobile'
          )
            this.menuToggle();
        },
        false
    );
  }
};
</script>

<style lang="scss">
</style>
